<template>
  <div class="fb-search">
    <!-- <div class="search-area-local" v-if="local" @click="clickHandler"> -->
    <div class="search-area-local"
         v-if="local"
         @click="search">

      <div class="search-area">
        <i class="iconfont icon-search"></i>
        <div class="search-text">搜索</div>
      </div>
      <input class="search-input" />
    </div>
    <div class="search-area-full"
         v-else>
      <div class="search-area">
        <div class="input-area">
          <i class="iconfont icon-search"></i>
          <input class="search-input-full"
                 placeholder="搜索"
                 v-model="searchKey" />
        </div>
        <span class="cancel-text"
              @click="cancelSearch">取消</span>
      </div>
      <div class="search-results">
        <p class="search-result"
           v-for="result in filterResults"
           :key="result"
           @click="toTalk(result)">
          <label class="label-result">{{ result }}</label>
        </p>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'search',
  props: {
    results: Array
  },
  data () {
    return {
      local: true,
      searchKey: '',
      filterResults: []
    }
  },
  methods: {
    clickHandler () {
      this.local = false
    },
    search () {
      // eslint-disable-next-line
      AND2JS.getRecentContacts()
      // eslint-disable-next-line
      AND2JS.showIMSearch()
    },
    cancelSearch () {
      this.local = true
    },
    toTalk (contact) {
      this.$router.push({ name: 'talk', params: { clientId: contact } })
    }
  },
  watch: {
    searchKey (val) {
      if (!val) {
        this.filterResults = []
        return
      }
      this.filterResults = this.results.filter(value => new RegExp(val, 'i').test(value))
    }
  }
}
</script>

<style lang="stylus" scoped>
.fb-search
  width 100%
  height 45px
  width 100%
  position relative
  border-bottom solid 0.5px #eaeaea
  .search-area-local
    background-color rgba(64, 180, 233, 0.23)
    padding 5px 15px
    .search-area
      position absolute
      left 43%
      top 30%
      .icon-search
        font-size 15px
        float left
        position relative
        top 2px
        margin-right 2px
      .search-text
        font-size 14px
        float left
        color #828282
    .search-input
      width 345px
      height 35px
      border 1px solid #eaeaea
      border-radius 8px
      background-color white
  .search-area-full
    width 100%
    height 100%
    position fixed
    top 0
    z-index 1500
    background-color white
    .search-area
      float left
      width 100%
      height 40px
      padding 3px
      background-color #eaeaea
      .input-area
        border-radius 8px
        height 35px
        width 88%
        float left
        background-color white
        .icon-search
          font-size 20px
          position relative
          bottom 2px
          line-height 40px
        .search-input-full
          width 90%
          font-size 15px
          border none
          margin-bottom 13px
          border-radius 8px
          background white
    .cancel-text
      margin-left 5px
      font-size 15px
      line-height 35px
      color green
    .search-results
      margin-top 35px
      .search-result
        height 45px
        width 100%
        text-align left
        font-size 18px
        line-height 45px
        border-bottom 0.5px solid #eaeaea
        .label-result
          margin-left 5px
</style>
