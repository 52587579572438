<template>
  <div>
    <msg-search></msg-search>
    <ul class="msg-list">
      <li
        class="msg-summary"
        v-for="(msg, index) in msgslist"
        :key="index"
        @click="enterChat(msg)"
      >
        <img :src="msg.img" class="msg-img" />
        <div :class="msg.count > 0 ? 'msg-count' : 'msg-count-none'">
          {{ msg.count }}
        </div>
        <div class="msg-item">
          <span class="msg-name">{{ msg.name }}</span>
          <span class="msg-time" v-if="situation == 'and'">{{
            msg.time | timestampToTime
          }}</span>
          <span class="msg-time" v-if="situation == 'ios'">{{ msg.time }}</span>
          <span class="msg-time" v-if="situation == 'flu'">{{ msg.time }}</span>
          <br />
          <span class="msg-content">{{ msg.content }}</span>
        </div>
      </li>
    </ul>
    <!-- <button @click="test()">ddddd</button> -->
  </div>
</template>

<script>
import MsgSearch from './search'
import { mapState } from 'vuex'
export default {
  data() {
    return {
      msgslist: [],
      situation: '',
      // msgslist: [
      //   {
      //     name: '陈申律师',
      //     count: 1,
      //     content: '你好,陈律师',
      //     time: '2018/04/23',
      //     count: 1
      //   },
      //   {
      //     name: '陈申律师',
      //     content: '你好,陈律师,我是沙发是的发送到发撒的法师打发第三方asdfadsf',
      //     time: '2018/04/23',
      //     count: 2
      //   }
      // ]
    }
  },
  computed: {
    ...mapState(['caseDetail']),
  },
  components: {
    MsgSearch,
  },
  methods: {
    enterChat(msg) {
      if (this.situation === 'and') {
        // 传入startChatSession中参数依次是type,contentId,caseId,文书id,证据Id
        // eslint-disable-next-line
        AND2JS.startChatSession(
          msg.type,
          msg.classid,
          this.$route.params.caseId,
          this.caseDetail.docId,
          this.caseDetail.evidenceId
        )
      } else if (this.situation === 'ios') {
        window.webkit.messageHandlers.startChatSession.postMessage({
          id: msg.classid,
          type: msg.type,
          caseId: this.$route.params.caseId,
          docId: this.caseDetail.docId,
          evidenceId: this.caseDetail.evidenceId,
        })
      } else if (this.situation === 'flu') {
        window.startChatSession.postMessage(
          JSON.stringify({
            id: msg.classid,
            type: msg.type,
            caseId: this.$route.params.caseId,
            docId: this.caseDetail.docId,
            evidenceId: this.caseDetail.evidenceId,
          })
        )
      } else {
        this.$toast('非原生环境 不支持聊天')
      }
    },
    ios() {
      window.webkit.messageHandlers.getRecentContacts.postMessage({})
      window.showiosdata = function () {
        console.log('123')
      }
    },
  },
  filters: {
    timestampToTime: function (value) {
      var date = new Date(value)
      var Y = date.getFullYear() + '/'
      var M =
        (date.getMonth() + 1 < 10 ? '0' + (date.getMonth() + 1) : date.getMonth() + 1) +
        '/'
      var D = date.getDate() + ' '
      var h = date.getHours() + ':'
      var m = date.getMinutes()
      if (m < 10) {
        m = '0' + m
      }
      var today = new Date()
      today.setHours(0)
      today.setMinutes(0)
      today.setSeconds(0)
      today.setMilliseconds(0)
      var todayTimestamp = today.getTime()
      if (value > todayTimestamp) {
        return h + m
      } else {
        return Y + M + D
      }
    },
  },
  created() {
    // 判断终端
    const u = navigator.userAgent
    if (u === 'fb-Android-Webview') {
      this.situation = 'and'
    } else if (u === 'fb-iOS-Webview') {
      // eslint-disable-next-line
      this.situation = 'ios'
    } else if (u === 'fb-flutter-Webview') {
      // eslint-disable-next-line
      this.situation = 'flu'
    }
    window.showiosdata = function (res) {
      this.msgslist = []
      const data = JSON.parse(res)
      for (const item of data) {
        var newChat = {}
        newChat.img = item.headUrl
        newChat.name = item.name
        newChat.content = item.message
        newChat.count = item.count
        newChat.time = item.time
        newChat.classid = item.sessionId
        newChat.type = item.sessionType
        this.msgslist.push(newChat)
      }
    }.bind(this)
  },
  mounted() {
    // 协作人id
    const accidsArray = this.caseDetail.accidsAndTid.accids
    // 文书证据id
    // let docid = this.caseDetail.docId
    // let evidenceId = this.caseDetail.evidenceId
    // let caseid = this.$route.params.caseId
    if (this.situation === 'and') {
      // eslint-disable-next-line
      AND2JS.getRecentContacts()
    } else if (this.situation === 'ios') {
      window.webkit.messageHandlers.getRecentContacts.postMessage({})
    } else if (this.situation === 'flu') {
      window.getRecentContacts.postMessage('{}')
    }
    window.showdata = function (res) {
      this.msgslist = []
      for (const item of res.data) {
        var newChat = {}
        newChat.classid = item.a
        newChat.personid = item.b
        // eslint-disable-next-line
        newChat.img = AND2JS.getUserAvatar(item.b)
        // eslint-disable-next-line
        newChat.name = AND2JS.getUserName(item.a, item.f)
        newChat.type = item.f
        newChat.content = item.g
        newChat.time = item.h
        newChat.count = item.d
        // AND2JS.startChatSession(newChat.type, newChat.classid, caseid, docid, evidenceId)
        if (
          newChat.name === '0000接单系统通知0000' ||
          accidsArray.indexOf(item.a) === -1
        ) {
          continue
        }
        this.msgslist.push(newChat)
      }
      // const a=res.data[0].a
      // const c = AND2JS.getUserAvatar(res.data[1].a)
      // const d = AND2JS.getUserName('4723968041', 'P2P');
      // this.a=res.data[1].b;
    }.bind(this)
    window.updatemsg = function (res) {
      function setflag(a, b) {
        for (let i = 0; i < b.length; i++) {
          if (a === b[i].classid) {
            return i
          }
        }
        return -1
      }
      for (const item of res.data) {
        const flag = setflag(item.a, this.msgslist)
        if (flag === -1) {
          var newChat = {}
          newChat.classid = item.a
          newChat.personid = item.b
          // eslint-disable-next-line
          newChat.img = AND2JS.getUserAvatar(item.b)
          // eslint-disable-next-line
          newChat.name = AND2JS.getUserName(item.a, item.f)
          newChat.type = item.f
          newChat.content = item.g
          newChat.time = item.h
          newChat.count = item.d
          if (!newChat.name === '0000接单系统通知0000') {
            this.msgslist.push(newChat)
          }
        } else {
          // alert(flag)
          this.msgslist[flag].content = item.g
          this.msgslist[flag].count += 1
          this.msgslist[flag].time = item.h
          // eslint-disable-next-line
          this.msgslist[flag].img = AND2JS.getUserAvatar(item.b)
          const newmsg = this.msgslist[flag]
          this.msgslist.splice(flag, 1)
          this.msgslist.unshift(newmsg)
        }
      }
    }.bind(this)
  },
}
</script>

<style lang="stylus" scoped>
.msg-search
  height 45px
  width 100%
  background-color #40B4E9
  opacity 0.23
.msg-summary
  padding 7px 15px
  height 51px
  border-bottom 0.026667rem solid #eeeeee
  position relative
.msg-item
  position relative
  width 295px
  display inline-block
  height 40px
  margin-left 10px
.msg-img
  width 38px
  height 38px
  line-height 40px
  // background: lightblue;
  border-radius 50%
  display inline-block
.msg-count
  width 14px
  height 14px
  border-radius 7px
  background-color #FF0000
  left 42px
  top 7px
  display inline-block
  position absolute
  box-sizing border-box
  color #FFFFFF
  font-size 13px
  text-align center
  line-height 18px
.msg-count-none
  display none
.msg-name
  color #666666
  font-size 15px
.msg-time
  position absolute
  color #999999
  font-size 11px
  top 0px
  right 0px
.msg-content
  display inline-block
  width 260px
  color #999999
  overflow hidden
  text-overflow ellipsis
  white-space nowrap
  font-size 12px
</style>
