var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c("msg-search"),
      _c(
        "ul",
        { staticClass: "msg-list" },
        _vm._l(_vm.msgslist, function (msg, index) {
          return _c(
            "li",
            {
              key: index,
              staticClass: "msg-summary",
              on: {
                click: function ($event) {
                  return _vm.enterChat(msg)
                },
              },
            },
            [
              _c("img", { staticClass: "msg-img", attrs: { src: msg.img } }),
              _c(
                "div",
                { class: msg.count > 0 ? "msg-count" : "msg-count-none" },
                [_vm._v(" " + _vm._s(msg.count) + " ")]
              ),
              _c("div", { staticClass: "msg-item" }, [
                _c("span", { staticClass: "msg-name" }, [
                  _vm._v(_vm._s(msg.name)),
                ]),
                _vm.situation == "and"
                  ? _c("span", { staticClass: "msg-time" }, [
                      _vm._v(_vm._s(_vm._f("timestampToTime")(msg.time))),
                    ])
                  : _vm._e(),
                _vm.situation == "ios"
                  ? _c("span", { staticClass: "msg-time" }, [
                      _vm._v(_vm._s(msg.time)),
                    ])
                  : _vm._e(),
                _vm.situation == "flu"
                  ? _c("span", { staticClass: "msg-time" }, [
                      _vm._v(_vm._s(msg.time)),
                    ])
                  : _vm._e(),
                _c("br"),
                _c("span", { staticClass: "msg-content" }, [
                  _vm._v(_vm._s(msg.content)),
                ]),
              ]),
            ]
          )
        }),
        0
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }