var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "fb-search" }, [
    _vm.local
      ? _c(
          "div",
          { staticClass: "search-area-local", on: { click: _vm.search } },
          [_vm._m(0), _c("input", { staticClass: "search-input" })]
        )
      : _c("div", { staticClass: "search-area-full" }, [
          _c("div", { staticClass: "search-area" }, [
            _c("div", { staticClass: "input-area" }, [
              _c("i", { staticClass: "iconfont icon-search" }),
              _c("input", {
                directives: [
                  {
                    name: "model",
                    rawName: "v-model",
                    value: _vm.searchKey,
                    expression: "searchKey",
                  },
                ],
                staticClass: "search-input-full",
                attrs: { placeholder: "搜索" },
                domProps: { value: _vm.searchKey },
                on: {
                  input: function ($event) {
                    if ($event.target.composing) return
                    _vm.searchKey = $event.target.value
                  },
                },
              }),
            ]),
            _c(
              "span",
              { staticClass: "cancel-text", on: { click: _vm.cancelSearch } },
              [_vm._v("取消")]
            ),
          ]),
          _c(
            "div",
            { staticClass: "search-results" },
            _vm._l(_vm.filterResults, function (result) {
              return _c(
                "p",
                {
                  key: result,
                  staticClass: "search-result",
                  on: {
                    click: function ($event) {
                      return _vm.toTalk(result)
                    },
                  },
                },
                [
                  _c("label", { staticClass: "label-result" }, [
                    _vm._v(_vm._s(result)),
                  ]),
                ]
              )
            }),
            0
          ),
        ]),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "search-area" }, [
      _c("i", { staticClass: "iconfont icon-search" }),
      _c("div", { staticClass: "search-text" }, [_vm._v("搜索")]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }